<style>
    .titleLogin {
        font-weight: 400;
        font-size: 20px;
        color: #265599;
        margin-top: 10px;
    }

    .ltitle {
        font-weight: 500;
        font-size: 20px;
        color: #666666;
        margin-top: 41px;
        text-align: left;
        width: 250px;

    }



    .linput {
        margin-top: 0px;
        width: 280px;
        display: flex;
        align-items: center;
    }

    .item {
        font-weight: 400;
        font-size: 12px;
        color: rgba(38, 85, 153, 0.85);
        margin-top: 17px;
    }

    body {
        background-color: white;
    }


    .time {
        width: 50px;
    }

    .login_button {
        background-color: #265599;
    }
</style>
<template>

    <div v-if="wxShow" style="display: flex;flex-direction:column;align-items: center;justify-content: center;">
        <div style="margin-top: 36px">
            <img src="../assets/login1.png" style="min-width: 220px;min-height: 156px;width: 220px"/>
        </div>

        <div class="titleLogin">陕西省首届密码技术竞赛</div>
        <el-form :model="login_data" :rules="rulesLogin" ref="ruleLogin" label-width="0px" class="demo-ruleForm">
            <div style="margin-top: 20px">
                <el-form-item label="" prop="name" class="linput">
                    <div class="linput">
                        <el-input maxlength="11" prefix-icon="el-icon-user" style="border: none !important; "
                                  placeholder="请输入手机号"
                                  v-model="login_data.name" clearable>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="pwd" class="linput">
                    <div class="linput">
                        <el-input prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="login_data.pwd"
                                  show-password></el-input>
                    </div>
                </el-form-item>

                <div style="display: flex;margin-top: 20px;align-items: center;justify-content: space-between">
                    <div style="background-color: #1c6ca1;padding: 5px 15px;letter-spacing: 2px;color: aquamarine;width: 70px;border-radius: 3px"
                         @click="createCode(4)">{{code}}
                    </div>
                    <span style="margin-left:5px;font-size: 12px " @click="createCode(4)">看不清换一张</span>
                    <el-form-item label="" prop="mcode" style="margin: 0">
                        <el-input
                                style="border: none !important; width: 100px !important;padding: 0 !important;margin: 0;font-size: 12px"
                                placeholder="验证码"
                                v-model="login_data.mcode" clearable>
                        </el-input>
                    </el-form-item>
                </div>


            </div>

            <div style="display: flex;flex-direction:row;justify-content:space-between;width: 280px">
                <div class="item" @click="goreg">立即注册</div>
                <div class="item" @click="messageShow=true">忘记密码？</div>

            </div>

            <div @click="login(1)"
                 style="background-color:#265599;width: 280px;color: white;padding: 13px 120px;font-size: 16px;margin-top: 30px;border-radius: 5px ">
                登 录
            </div>
        </el-form>

        <el-dialog title="重要提示" width="90%" :visible.sync="rloginShow">
            <span>{{rloginMessage}}</span>
            <span slot="footer" class="dialog-footer">
            <el-button @click="rloginShow = false" size="mini">取 消</el-button>
            <el-button type="primary" @click="login(2)" size="mini" style="background-color: #265599">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog title="" width="90%" :visible.sync="messageShow">

            <div>
                <el-form ref="form" label-width="70px" :inline="true" class="login-container" :model="form"
                         :rules="rules">

                    <el-form-item label="手机号" prop="CellPhone" ref="CellPhone">
                        <el-input v-model="form.CellPhone" placeholder="请输入手机号">
                            <el-select placeholder="+86"></el-select>
                        </el-input>
                    </el-form-item>


                    <el-form-item label="验证码" prop="VerificationCode">
                        <el-input v-model="form.VerificationCode" placeholder="验证码" style="width: 100px !important;"></el-input>
                        <el-button @click="getCode"
                                   style="margin-left: 10px;padding: 13px 10px;font-size: 12px;width: 70px ">
                            <span style="">{{btnTxt}}</span>
                        </el-button>
                    </el-form-item>

                    <div style="text-align: right;padding-right: 40px;margin-top: 20px">

                        <el-form-item>
                            <div style="display: flex;justify-content: right">
                                <el-button @click="messageShow=false" class="login_button" type="primary">取消</el-button>
                                <el-button @click="submit" class="login_button" type="primary">确认重置</el-button>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
    <div v-else>
        <img src="../assets/wbg.jpg" style="width: 100vw;"/>
    </div>
</template>
<script>
    import request from "../plugins/axios.js";

    export default {
        data() {
            let that = this
            //手机号
            let checkName = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入手机号'));
                }

                let phone = /^1{1}[0-9]{10}$/;
                if (!phone.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                }
                callback();
            };
            //验证码
            let checkCode = (rule, value, callback) => {
                console.log(value)
                let code = that.code
                console.log(code)
                if (value !== code) {
                    callback(new Error('验证码错误'));
                }

                callback();
            };

            //短信手机号
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入手机号'));
                }

                let phone = /^1{1}[0-9]{10}$/;
                if (!phone.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                }
                callback();
            };
            return {
                btnTxt: "验证码",
                // 是否禁用  即点击之后一段时间内无法再点击
                disabled: false,
                time: 0,
                form: {
                    CellPhone: '',
                    VerificationCode: '',
                },
                rules: {
                    CellPhone: [
                        {required: true, trigger: 'blur', validator: checkPhone}
                    ],
                    VerificationCode: [
                        {required: true, trigger: 'blur', message: '请输入4位验证码'},
                        {required: true, trigger: 'blur', min: 6, max: 6, message: '验证码错误'}
                    ],
                },
                messageShow: false,

                code: '',

                //登录
                rulesLogin: {
                    name: [
                        {validator: checkName, trigger: 'blur'},
                    ],
                    pwd: [
                        {required: true, message: '请输入密码',}
                    ],
                    mcode: [
                        {validator: checkCode, trigger: 'blur'},
                    ],
                },


                rloginShow: false,
                rloginMessage: '',
                wxShow: false,
                //登录报错信息
                loginError: false,
                loginErrorMessage: "",
                //登录表单
                login_data: {
                    name: "",
                    pwd: "",
                    mcode: ''
                },
            };
        },
        components: {},
        mounted() {
            document.title ="陕西省首届密码技术竞赛"
            let that = this
            // 获取 User Agent
            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
                return false
            }
            let lurl = localStorage.getItem('lurl')
            localStorage.clear()
            if (lurl) {
                localStorage.setItem('lurl', lurl)
            }
            that.createCode(4);

        },
        methods: {

            //生成验证码的方法
            createCode(length) {
                let that = this
                var code = "";
                var codeLength = parseInt(length); //验证码的长度
                //所有候选组成验证码的字符，当然也可以用中文的
                var codeChars = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
                //循环组成验证码的字符串
                for (var i = 0; i < codeLength; i++) {
                    //获取随机验证码下标
                    var charNum = Math.floor(Math.random() * 62);
                    //组合成指定字符验证码
                    code += codeChars[charNum];
                }
                that.code = code
            },
            getCode() {
                let that = this
                // 校验手机号码
                if (!this.form.CellPhone) {
                    //号码校验不通过
                    this.$notify.error("请输入手机号");
                    //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
                } else if (!/1[35789]\d{9}/.test(this.form.CellPhone)) {
                    // 失去焦点后自动触发校验手机号规则
                } else {
                    this.time = 60;
                    this.disabled = true;
                    //调用倒计时方法

                    // 封装的axios接口
                    request({
                        url: "/mn/send",
                        data: {
                            phone: that.form.CellPhone,
                        },
                    }).then((ret) => {
                        if (ret.code == 200) {
                            this.timer();
                        } else {
                            that.$notify.error(ret.message)
                        }

                    })
                }
            },
            // 倒计时方法
            timer() {
                if (this.time > 0) {
                    this.time--;
                    // console.log(this.time);
                    this.btnTxt = this.time + "s";
                    setTimeout(this.timer, 1000);
                } else {
                    this.time = 0;
                    this.btnTxt = "验证码";
                    this.disabled = false;
                }
            },
            // 提交按钮
            submit() {
                let that = this
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        console.log('upr')
                        request({
                            url: "/mn/upr",
                            data: {
                                phone: that.form.CellPhone,
                                code: that.form.VerificationCode
                            },
                        }).then((ret) => {
                            if (ret.code == 200) {
                                that.messageShow = false
                                that.$notify.success(ret.message)
                            } else {
                                that.$notify.error(ret.message)
                            }
                        })
                    }
                });

            },

            goreg() {
                this.$router.push("/reg");
            },
            goHome() {
                this.$router.push("/home");
            },
            login(qz) {
                let that = this

                this.$refs["ruleLogin"].validate((valid) => {
                    if (valid) {
                        //提交表单
                        request({
                            url: "/mn/login",
                            data: {
                                name: this.login_data.name,
                                pwd: this.login_data.pwd,
                                qz: qz
                            },
                        }).then((ret) => {
                                if (ret.code == 200) {
                                    localStorage.setItem('u', JSON.stringify(ret.data))
                                    let lurl = localStorage.getItem('lurl')
                                    if (lurl) {
                                        this.$router.push(lurl);
                                    } else {
                                        this.$router.push("/home");
                                    }

                                    return true;
                                } else if (ret.code == 206) {
                                    that.rloginMessage = ret.message
                                    that.rloginShow = true
                                } else {
                                    that.$message.error(ret.message);
                                }
                            },
                            (err) => {
                                console.log(err)
                                this.loginError = true;
                                this.loginErrorMessage = err;
                            }
                        );
                    }
                })


            },
        },
    };
</script>
